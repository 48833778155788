import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import type { IHydrateAction } from 'sp-redux';
import { getAutoPromotionSlotsThunk } from 'sp-redux/thunks/services/getAutoPromotionSlots';
import { getPaymentOrderInfoThunk } from 'sp-redux/thunks/services/getPaymentOrderInfoThunk';
import { getServicePromocode } from 'sp-redux/thunks/services/getServicePromocode';
import { getServiceTypesGroup } from 'sp-redux/thunks/services/getServiceTypesGroup';
import { getServicesList } from 'sp-redux/thunks/services/getServicesList';
import { postAutoPromotionArtThunk } from 'sp-redux/thunks/services/postAutoPromotionArt';
import { postCreateAndSendThunk } from 'sp-redux/thunks/services/postCreateAndSendThunk';

import { IServiceStateType } from './types';

const initialState: IServiceStateType = {
  isFetched: false,
  isFetching: false,
  services: [],
  circulation: {
    result: null,
    errors: [],
  },
  servicesGroup: [],
  paymentOrderInfo: {
    result: null,
    errors: [],
  },
  promocode: {
    result: {
      cost: '',
      final_cost: '',
      cost_delta: '',
      discount_percentage: '-',
    },
    isFetching: false,
    errors: [],
  },
  promotion: {
    auto: {
      isFetching: false,
      slots: null,
      errors: [],
      payment: {
        result: null,
        errors: [],
      },
    },
  },
};

const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    clearPromocodeErrors: state => {
      state.promocode.errors = [];
    },
    clearServicePromocode: state => {
      state.promocode.result = {
        cost: '',
        final_cost: '',
        cost_delta: '',
        discount_percentage: '-',
      };
    },
  },

  extraReducers: builder => {
    builder.addCase(getServicesList.fulfilled, (state, action) => {
      state.isFetched = true;
      state.services = action.payload.result;
    });

    builder.addCase(postCreateAndSendThunk.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(getAutoPromotionSlotsThunk.pending, state => {
      state.promotion.auto.isFetching = true;
    });

    builder.addCase(postAutoPromotionArtThunk.pending, state => {
      state.promotion.auto.isFetching = true;
    });

    builder.addCase(postCreateAndSendThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;

      state.circulation.errors = action.payload.errors;
      state.circulation.result = action.payload.result;
    });

    builder.addCase(getServiceTypesGroup.fulfilled, (state, action) => {
      state.isFetched = true;
      state.servicesGroup = action.payload.result;
    });

    builder.addCase(getPaymentOrderInfoThunk.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(getPaymentOrderInfoThunk.fulfilled, (state, action) => {
      state.isFetched = true;
      state.isFetching = false;

      state.paymentOrderInfo.errors = action.payload.errors;
      state.paymentOrderInfo.result = action.payload.result;
    });

    builder.addCase(getServicePromocode.pending, state => {
      state.promocode.isFetching = true;
    });

    builder.addCase(getServicePromocode.fulfilled, (state, action) => {
      state.promocode.isFetching = false;
      state.promocode.errors = action.payload.errors;
      state.promocode.result = action.payload.result;
    });

    builder.addCase(getAutoPromotionSlotsThunk.fulfilled, (state, action) => {
      state.promotion.auto.isFetching = false;
      state.promotion.auto.errors = action.payload.errors;
      state.promotion.auto.slots = action.payload.result;
    });

    builder.addCase(postAutoPromotionArtThunk.fulfilled, (state, action) => {
      state.promotion.auto.isFetching = false;
      state.promotion.auto.payment.errors = action.payload.errors;
      state.promotion.auto.payment.result = action.payload.result;
    });

    builder.addCase(
      HYDRATE,
      (state, action: IHydrateAction<IServiceStateType>) => {
        return action.payload.services;
      },
    );
  },
});

export const { clearPromocodeErrors, clearServicePromocode } =
  servicesSlice.actions;

export const servicesReducer = servicesSlice.reducer;
