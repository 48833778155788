import { uuid4 } from '@sentry/utils';

import { typografFixText } from 'utils/typograf';

import { IPrompt } from './types';

export const MAX_ACHIEVEMENTS_LEVEL = 5;

export const SALES_PROMPTS: IPrompt[] = [
  {
    id: uuid4(),
    title: 'Почему это важно?',
    description: typografFixText(
      'Еженедельное получение продаж вместе с просмотрами, высоким оценками и отзывами влияет на позицию вашей книги в каталоге и жанре, поэтому важно поддерживать их постоянство.',
    ),
  },
  {
    id: uuid4(),
    title: 'Как обеспечить регулярность?',
    description: typografFixText(
      'Задайте первоначальный старт, поделившись ссылкой на книгу в тематических сообществах, в коммьюнити Авторов Литрес, с друзьями и родными - часто именно получение такой первой поддержки помогает книге взлететь и найти свою аудиторию.',
    ),
  },
  {
    id: uuid4(),
    title: 'Привлекательное оформление',
    description: typografFixText(
      'Убедитесь, что у книги качественная, понятная и цепляющая аннотация, обложка уникальная и визуально отражает суть книги. Цепляющая обложка и название - первое, что влияет на переход к книге.',
    ),
  },
  {
    id: uuid4(),
    title: 'Регулярные новые публикации',
    description: typografFixText(
      'Помните про накопительный эффект: с каждой новой публикацией возрастает интерес читателей и к предыдущим вашим книгам.',
    ),
  },
  {
    id: uuid4(),
    title: 'Новые форматы',
    description: typografFixText(
      'По статистике, книги имеющие аудиоверсию покупают чаще. Передайте аудиоправа Литрес и мы сделаем аудиоверсию.',
    ),
  },
  {
    id: uuid4(),
    title: 'Конкурсы',
    description: typografFixText(
      'Участвуйте в конкурсах - многие топовые авторы Литрес раскрутились именно благодаря конкурсам и победам в них.',
    ),
  },
];

export const REVIEWS_PROMPTS: IPrompt[] = [
  {
    id: uuid4(),
    title: 'Доверие читателей',
    description: typografFixText(
      'Чем больше у книги отзывов, тем проще пользователю на Литрес принять решение о покупке, ведь они формируют доверие к книге.',
    ),
  },
  {
    id: uuid4(),
    title: 'Формирование своего коммьюнити',
    description: typografFixText(
      'Вы можете отвечать на отзывы читателей, там самым формируя коммьюнити. Возможность получить ответ от автора также мотивирует читателей писать отзывы и задавать в них свои вопросы о героях и сюжете, а бонусом обеспечивает яркий эмоциональный отклик.',
    ),
  },
  {
    id: uuid4(),
    title: 'Поддерживающие алгоритмы',
    description: typografFixText(
      'Еженедельные отзывы вместе с высокими оценками, продажами и просмотрами влияют на позицию вашей книги в каталоге и жанре, поэтому важно поддерживать их постоянство.',
    ),
  },
  {
    id: uuid4(),
    title: 'Как получить первые отзывы?',
    description: typografFixText(
      'Делитесь ссылками на ваши книги в соцсетях в тематических группах, с друзьями и коллегами, в коммьюнити авторов, обменивайтесь отзывами и обратной связью. Первые отзывы служат бустом к тому, чтобы дальше читатели начинали писать их сами.',
    ),
  },
];

export const RATING_PROMPTS: IPrompt[] = [
  {
    id: uuid4(),
    title: 'Больше читателей',
    description: typografFixText(
      'Многие читатели смотрят не только на оценку книги, но и на количество оценок - это помогает им принять решение о покупке и переходе к чтению. Большее количество оценок вызывает больший уровень доверия к книге.',
    ),
  },
  {
    id: uuid4(),
    title: 'Фильтры Литрес',
    description: typografFixText(
      'На Литрес есть возможность выставить популярный фильтр «Высокая оценка», который будет показывать только книги с рейтингом 4 и 5 звёзд. Наличие высокой оценки у вашей книги обеспечивает ее отображение в каталоге, даже если пользователь включил этот фильтр.',
    ),
  },
  {
    id: uuid4(),
    title: 'Как получить первые оценки?',
    description: typografFixText(
      'Делитесь ссылками на ваши книги в соцсетях в тематических группах, с друзьями и коллегами, в коммьюнити авторов, обменивайтесь оценками и обратной связью. Первые оценки служат бустом к тому, чтобы дальше читатели начинали ставить их сами.',
    ),
  },
];

export const PUBLICATIONS_PROMPTS: IPrompt[] = [
  {
    id: uuid4(),
    title: 'Регулярность публикаций',
    description: typografFixText(
      'Каждая новая публикация отображается в Новинках Литрес. При регулярных публикациях большее количество читателей их увидит, а для алгоритмов Литрес вы будете активным писателем.',
    ),
  },
  {
    id: uuid4(),
    title: 'Накопительный эффект',
    description: typografFixText(
      'Читатель, которому понравилась ваша книга, обязательно перейдет на вашу страницу автора в поиске новых книг. Позаботьтесь о том, чтобы удержать его интерес и дать читателю еще несколько ваших произведений.',
    ),
  },
  {
    id: uuid4(),
    title: 'Быстрая обратная связь от читателей',
    description: typografFixText(
      'Разделив крупное произведение на части, вы можете публиковать их как отдельные книги. Ваши книги находят своего читателя, и у вас начинает формироваться свое коммьюнити читателей.',
    ),
  },
  {
    id: uuid4(),
    title: 'Новые форматы',
    description: typografFixText(
      'По статистике, книги, имеющие аудиоверсию, покупают больше. Наличие аудиоверсии приводит новую аудиторию и повышает интерес к электронной версии. Передайте аудиоправа Литрес и мы сделаем аудиоверсию.',
    ),
  },
];
