import { createSlice } from '@reduxjs/toolkit';
import { IApiResponseErrorField } from 'app.types';
import { IPerson } from 'modules/persons/types';

import { getPersonThunk } from 'sp-redux/thunks/persons/getPersonThunk';
import { getPersonsThunk } from 'sp-redux/thunks/persons/getPersonsThunk';

export interface IPersonsState {
  persons: IPerson[];
  isFetching: boolean;
  isFetched: boolean;
  errors: IApiResponseErrorField[] | null;
}

export const initialPersonsState: IPersonsState = {
  persons: [],
  isFetching: false,
  isFetched: false,
  errors: null,
};

const personsSlice = createSlice({
  name: 'persons',
  initialState: initialPersonsState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getPersonsThunk.pending, state => {
      state.isFetching = true;
      state.errors = null;
    });
    builder.addCase(getPersonsThunk.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isFetched = true;

      state.errors = action.payload.errors;

      if (state.errors.length === 0) {
        state.persons = action.payload.result;
      }
    });
  },
});

export const personsReducer = personsSlice.reducer;

export interface IPersonState {
  currentPerson: IPerson | null;
  isFetching: boolean;
  isFetched: boolean;
  errors: IApiResponseErrorField[] | null;
}

export const initialPersonState: IPersonState = {
  currentPerson: null,
  isFetching: false,
  isFetched: false,
  errors: null,
};

const personSlice = createSlice({
  name: 'currentPerson',
  initialState: initialPersonState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getPersonThunk.pending, state => {
      state.isFetching = true;
      state.errors = null;
    });
    builder.addCase(getPersonThunk.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isFetched = true;

      state.errors = action.payload.errors;

      if (state.errors.length === 0) {
        state.currentPerson = action.payload.result;
      }
    });
  },
});

export const personReducer = personSlice.reducer;
