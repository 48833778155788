import { createAsyncThunk } from '@reduxjs/toolkit';
import type { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export const getAvalibleDiscountsArtsThunk = createAsyncThunk(
  'discounts/avalibleDiscountsArts',
  async (context?: GetServerSidePropsContext) => {
    const apiPointName = 'getAvailibleDiscountsArts';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      context,
    });
  },
);
