/*
  eslint-disable
    @typescript-eslint/no-unsafe-member-access,
    @typescript-eslint/no-unsafe-call,
    @typescript-eslint/restrict-template-expressions
*/
import * as Sentry from '@sentry/nextjs';
import type { IAPIResponse, IPublicRuntimeConfig } from 'app.types';
import createDebug from 'debug';
import type { GetServerSidePropsContext, NextPageContext } from 'next';
import getConfig from 'next/config';
import queryString from 'query-string';

import type {
  IApiPointsFetchParamsType,
  TAvailableApiPoints,
} from 'constants/apiPoints';
import { apiPoints } from 'constants/apiPoints';

import { getBaseUrl } from 'utils/baseUrl';

import { logFetchInfo, logFetchError } from './logFetchData';

type TMethod = 'GET' | 'POST';

const debug = createDebug('PS:fetch');

interface IResponseError {
  messages?: string[];
}

interface IResponse {
  errors: IResponseError[];
}

export async function doFetch<Key extends keyof IApiPointsFetchParamsType>({
  apiPointName,
  body,
  context,
  urlParams,
  queryParams,
  queryFormatComma,
  contentType = 'application/json',
}: {
  apiPointName: TAvailableApiPoints;
  body?: IApiPointsFetchParamsType[Key]['request'];
  context?: GetServerSidePropsContext | NextPageContext;
  urlParams?: Record<string, string | number>;
  queryParams?: IApiPointsFetchParamsType[Key]['queryParams'];
  queryFormatComma?: boolean;
  contentType?: string;
}): Promise<IAPIResponse<IApiPointsFetchParamsType[Key]['response']>> {
  try {
    const { publicRuntimeConfig } = getConfig();
    const { host }: IPublicRuntimeConfig = publicRuntimeConfig;

    const method = apiPoints[apiPointName].method as TMethod;
    const initFetchParams: RequestInit = {
      method,
      headers: {
        accept: 'application/json',
        /* eslint-disable @typescript-eslint/naming-convention */
        'Content-Type': contentType,
        'X-Forwarded-Host': host.replace('https://', ''),
        /* eslint-enable */
      },
      credentials: 'include',
    };

    let { path } = apiPoints[apiPointName];
    const { getPath } = apiPoints[apiPointName];

    if (getPath && urlParams) {
      path = getPath(urlParams);
    }

    if (queryParams) {
      if (queryFormatComma) {
        path = `${path}?${queryString.stringify(queryParams, {
          arrayFormat: 'comma',
        })}`;
      } else {
        path = `${path}?${queryString.stringify(queryParams)}`;
      }
    }

    if (body) {
      initFetchParams.body = JSON.stringify(body);
    }

    if (body instanceof FormData) {
      initFetchParams.body = body;
      initFetchParams.headers = {
        ...initFetchParams.headers,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'multipart/form-data',
      };
    }

    if (context?.req?.headers.cookie) {
      initFetchParams.headers = {
        ...initFetchParams.headers,
        Cookie: context.req.headers.cookie,
      };
    }

    const baseUrl = getBaseUrl();
    const resource = `${baseUrl}${path}`;

    const response = await fetch(resource, initFetchParams);
    debug(`[${response.status}] ${resource}`);

    // TODO: Магия автологина:
    // Иногда маркетинг генерет хеш ссылки с автологином на почту пользователям
    // и при переходе по ссылке пользователь попадает на сайт и мы проверяем хэш,
    // если он есть, и он действителен, бек возвращает сессию и куку, которою нам
    // нужно сохранить из response в SSR.
    // убедившись что запрос SSR и есть 'set-cookie', мы регулярим sessionid
    // и записываем его  в res.setHeader
    if (context && response.headers.get('set-cookie')) {
      const cookies = response.headers.get('set-cookie');
      const sessionId = cookies?.match(/sessionid.+Secure/gi);

      if (sessionId?.[0]) {
        context.res?.setHeader('Set-Cookie', sessionId);
      }
    }

    logFetchInfo(
      response,
      method,
      context
        ? (context as GetServerSidePropsContext).resolvedUrl ||
            (context as NextPageContext).asPath
        : '',
    );

    if (response.status === 204) {
      return { result: [], errors: [] };
    }

    if (response.status > 499) {
      return {
        result: [],
        errors: [
          {
            field: '',
            messages: [`Backend error with status ${response.status}`],
          },
        ],
      };
    }

    const responseAsJson = await response.json();
    const { errors } = responseAsJson as IResponse;
    let firstMessage = '';
    if (
      errors.length &&
      errors[0].messages &&
      Array.isArray(errors[0].messages)
    ) {
      firstMessage = errors[0].messages[0];
    }
    if (response.status > 399) {
      Sentry.captureException(
        new Error(`Fetch error ${apiPointName}`),
        scope => {
          scope.setTransactionName(`Fetch error ${apiPointName}`);
          scope.setFingerprint([
            apiPoints[apiPointName].method,
            apiPointName,
            String(response.status),
          ]);
          scope.setExtras({
            body,
            message: firstMessage,
            e: errors[0],
          });
          return scope;
        },
      );
    }

    return responseAsJson as Promise<
      IAPIResponse<IApiPointsFetchParamsType[Key]['response']>
    >;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    logFetchError(
      err,
      context
        ? (context as GetServerSidePropsContext).resolvedUrl ||
            (context as NextPageContext).asPath
        : '',
    );

    Sentry.withScope(function (scope) {
      // group errors together based on their request and response
      scope.setFingerprint([
        apiPoints[apiPointName].method,
        apiPointName,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        String(err.statusCode),
      ]);
      Sentry.captureException(err);
    });

    return Promise.reject(err);
  }
}
