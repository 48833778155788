import { createAsyncThunk } from '@reduxjs/toolkit';
import { EDiscountArtStatus } from 'entities/Discounts/types';
import { GetServerSidePropsContext } from 'next';

import { doFetch } from 'utils/doFetch';

export interface IGetCreatedDiscountsArtsThunkProps {
  discount_status: EDiscountArtStatus;
  order_by?: string;
  limit?: number;
  offset?: number;
  context?: GetServerSidePropsContext;
}

export const getCreatedDiscountsArtsThunk = createAsyncThunk(
  'discounts/createdDiscountsArts',
  async ({
    discount_status,
    order_by,
    limit,
    offset,
    context,
  }: IGetCreatedDiscountsArtsThunkProps) => {
    const apiPointName = 'getDiscountsArts';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      queryParams: { discount_status, order_by, limit, offset },
      context,
    });
  },
);
