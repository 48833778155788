import { createAsyncThunk } from '@reduxjs/toolkit';

import { doFetch } from 'utils/doFetch';

export const getPersonThunk = createAsyncThunk(
  'persons/getPerson',
  async (personId: string) => {
    const apiPointName = 'getPerson';

    return await doFetch<typeof apiPointName>({
      apiPointName,
      urlParams: {
        personId,
      },
    });
  },
);
