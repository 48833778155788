import { IAccountTaxData } from 'entities/Account/types';
import type { TArtType } from 'entities/Art/types';
import type { IAudioBook } from 'entities/AudioBook/types';
import {
  IAudioBookServerPayload,
  IUnpublishAudioBookById,
} from 'entities/AudioBook/types';
import type { IBook, IPublishBookResponse } from 'entities/Book/types';
import { IGenerateAIAnnotationResponse } from 'entities/Book/types';
import type { IChapter } from 'entities/Chapter/types';
import type {
  IAICoverGenerateResponse,
  IBookCoverInfo,
} from 'entities/Cover/types';
import { IAICoverGenerateStatusResponse } from 'entities/Cover/types';
import type { IEmployee, IEmployeeRoleEntity } from 'entities/Employee/types';
import { IEmployeeInRequest } from 'entities/Employee/types';
import type { IBookFile, IDownloadInProgressFile } from 'entities/File/types';
import type { IGenreGroupedOption } from 'entities/Genre/types';
import { IBookVersions } from 'entities/Moderation/types';
import type { IMyBook } from 'entities/MyBook/types';
import type { IPodcast } from 'entities/Podcast/types';
import { IPodcastServerPayload } from 'entities/Podcast/types';
import type { IPrice } from 'entities/Price/types';
import type { IProfile } from 'entities/Profile/types';
import {
  IPublisherReadingStatisticsReportResponse,
  IPublisherSalesStatisticsReportResponse,
  IPublisherStatisticsReportGeneralTotal,
  IPublisherStatisticsReportResponse,
} from 'entities/PublisherStatistics/types';
import type {
  ISeries,
  ISeriesArtSearch,
  ISeriesArtSearchQueryParams,
} from 'entities/Series/types';
import { IService } from 'entities/Service';
import { ITag } from 'entities/Tag/types';
import type { IUserDirectAgreementType } from 'entities/User/types';
import type { IRestorePasswordParamsType } from 'modules/account/components/RestorePasswordForm/types';
import { getSlugByMetricType } from 'modules/achievements/helpers';
import {
  EGiftTypes,
  EMetricTypes,
  IAchievementProgressDataType,
  IAchievementRewardsDataType,
  IAchievementsGiftPromoType,
  IAchievementsGiftsType,
} from 'modules/achievements/types';
import type {
  IApplyAudioRequestType,
  IApplyAudioResponseType,
  ISetOrderAudioBookFilesRequest,
  ITransferRightsAudiobookRequestType,
  ITransferRightsAudiobookResponseType,
} from 'modules/audio-book/types';
import type { INotAuthorizedUser } from 'modules/book-card/pages/BookFound/types';
import type { IReview } from 'modules/comments/components/Review/types';
import type { ICommentReplyRequest } from 'modules/comments/pages/types';
import { IEditorJSBlock } from 'modules/editor-concept/types';
import type {
  IFeedbackFormType,
  IFeedbackTopicsType,
} from 'modules/feedback/components/FeedbackForm/types';
import type {
  IBookParseIdRequest,
  IBookParseIdResult,
  IImportIdsRequest,
  IParsedBook,
} from 'modules/import/types';
import type {
  IModeratorsAutocompleteRequestQueryParamsType,
  IResponseModeratorsAutocompleteTypes,
} from 'modules/moderation/components/FiltersModerator/components/ModeratorsInput/types';
import type {
  IProfileAutocompleteRequestQueryParamsType,
  IResponseProfileAutocompleteTypes,
} from 'modules/moderation/components/FiltersModerator/components/ProfilesInput/types';
import type { IModerationBookHistoryItem } from 'modules/moderation/components/ModerationHistory/types';
import type {
  ITakeBooksToModerationFetchTypes,
  ITakeBooksToModerationResponseTypes,
  ITakeProfileToModerationFetchTypes,
  ITakeProfileToModerationResponseTypes,
} from 'modules/moderation/types';
import { IPerson, IPersonUpdateQueryType } from 'modules/persons/types';
import type { ICoverDataType } from 'modules/podcast/components/SendSuccess/types';
import type {
  IPassportInfoAPIRequest,
  IPassportInfoAPIResponse,
  ISendAccountingDataTypes,
} from 'modules/statistics/components/NaturalPersonInfoForm/types';
import type { IFormRoyaltyApiTypes } from 'modules/statistics/components/PlatronRoyaltyForm/types';
import type {
  IPasswordRoyaltyValidationRequest,
  IPasswordRoyaltyValidationResponse,
} from 'modules/statistics/components/VerificationPasswordForm/types';
import type {
  ICheckRoyaltyOtcRequest,
  ICheckRoyaltyOtcResponse,
} from 'modules/statistics/pages/CodeVerification/types';
import type {
  ICheckUpdateINNRequest,
  ICheckUpdateINNResponse,
  IRequestUpdateYooStatus,
  IResponseUpdateYooStatus,
} from 'modules/statistics/pages/PaymentTypeResidents/types';
import type {
  IGetRawSumsRequestType,
  IGetRawSumsResponseType,
  IGetReadingStatisticsRequestType,
  IGetRoyaltyOverallQueryType,
  IGetRoyaltyOverallResponseType,
  IGetRoyaltyStatisticsQueryType,
  IGetRoyaltyStatisticsSumsType,
  IGetStatisticsAccessibilityResponse,
  IGetStatisticsChartQueryParamsType,
  IGetStatisticsChartRequestType,
  IGetStatisticsChartResponseType,
  IReadingStatisticsTableRow,
  IRoyaltyBannerResponse,
  IRoyaltyDraftResponse,
  IRoyaltyPayoutResponse,
  IRoyaltyReportResponse,
  IRoyaltyStatisticsTableRow,
  IRoyaltySums,
} from 'modules/statistics/pages/StatisticsPage/types';
import { ISendRoyaltyOtcResponse } from 'modules/statistics/types';
import type {
  IAdvancedOfferAgreementAPIRequest,
  IAdvancedOfferAgreementAPIResponse,
} from 'modules/status/components/INNForm/types';

import type {
  IGenresAutocompleteRequestQueryParamsType,
  IGenresAutocompleteResponseType,
} from 'components/shared/ArtGenres/types';
import type {
  ITagsAutocompleteRequestQueryParamsType,
  ITagsAutocompleteResponseType,
  ITagsByGenresRequestQueryParamsType,
} from 'components/shared/ArtTags/TagsInput/types';
import type { IResendMailResponse } from 'components/shared/MailConfirmation/types';
import type { IStaticPageType } from 'components/shared/StaticInfo/types';

import {
  IAchievementProgressForSalesQueryParams,
  IAchievementProgressSeenQueryParams,
  IAchievementRewardSeenQueryParams,
  IAchievementSeenRequest,
  IAchievementsGiftPromoQueryParams,
} from 'sp-redux/slices/achievements/types';
import type {
  IAudioBookCoverInfo,
  IAudioBookModerationComment,
} from 'sp-redux/slices/audioBookMeta/types';
import type {
  IBookCommentsResultType,
  IBookDiscountRequest,
  IBookDiscountResponse,
  IBookMetaType,
  ICharactersCountType,
  ICoverDocumentResponseType,
  IDiscountsInfoResponse,
  IDiscountsList,
  IDiscountsListQuery,
  IRejectedBook,
  ISavableBookMetaType,
  IServerBookMetaType,
  IUnpublishBookRequest,
} from 'sp-redux/slices/bookMeta/types';
import type {
  ICirculationCost,
  ICirculationCostQueryType,
  ICirculationCover,
  ICirculationGenerationQueryType,
  ICirculationPdf,
} from 'sp-redux/slices/circulation/types';
import type {
  ICommentTemplatesRequest,
  ICommentTemplatesType,
} from 'sp-redux/slices/commentTemplates/types';
import type { ICreateBookRequestType } from 'sp-redux/slices/createBook/types';
import {
  IDiscountsArtsQuery,
  IGetAvalibleDiscountsArts,
  IGetDiscountsArt,
} from 'sp-redux/slices/discounts/types';
import type { IServerDocumentType } from 'sp-redux/slices/document/types';
import type {
  IEmployeeRequest,
  IEmployeeResponse,
} from 'sp-redux/slices/employees/types';
import type {
  IAllQueueModerationResponseTypes,
  ICountersModerationBooksTypes,
  IFilteringQueryParamsType,
  IFinalQueueAPIResponseModerationTypes,
  IModerationBookInfo,
  IMyQueueModerationResponseTypes,
  IUpdateBookModerationStateRequest,
  IUpdateBookModerationStateResponse,
} from 'sp-redux/slices/moderator/types';
import { IQueueProfileTypes } from 'sp-redux/slices/moderator/types';
import type { TMyAudioBooksType } from 'sp-redux/slices/myAudioBooks/types';
import type {
  IMyBooksCount,
  IMyBooksQueryParams,
} from 'sp-redux/slices/myBooks/types';
import type {
  INotification,
  INotificationsCounters,
} from 'sp-redux/slices/notifications/types';
import type {
  IOauthLitresFinalizePost,
  IOauthLitresFinalizeResult,
  IOauthLitresScope,
} from 'sp-redux/slices/oauthLitres/types';
import type { IOfferBaseInfoType } from 'sp-redux/slices/offer/types';
import {
  IAutoPromotionStatisticsResponse,
  ICirculationOrder,
  ICirculationOrderUpdate,
  IGetCirculationOrdersQueryParams,
  IGetPromotionsOrdersQueryParams,
  IPromotionOrder,
  IServicesOrders,
  IServicesOrdersQueryParams,
  TGetCirculationOrdersAPIResponse,
} from 'sp-redux/slices/orders/types';
import type { IOwnersItem } from 'sp-redux/slices/owners/types';
import type { IEncryptKeyResponse } from 'sp-redux/slices/payments/types';
import { IMandarinRegisterType } from 'sp-redux/slices/payments/types';
import type { IPodcastModerationComment } from 'sp-redux/slices/podcastMeta/types';
import type { TSaveProfileRequestType } from 'sp-redux/slices/profile/types';
import { IPublisherInfoType } from 'sp-redux/slices/publisher/types';
import type {
  ICreateSeries,
  IGetSeriesQueryParams,
  IPatchSeriesRequest,
  ISeriesCounters,
  TGetSeriesAPIResponse,
  TGetSeriesDetailsAPIResponse,
} from 'sp-redux/slices/series/types';
import type {
  IEditSeriesAPIRequest,
  TEditSeriesAPIResponse,
} from 'sp-redux/slices/seriesDetails/types';
import {
  IAutoPromotionSlotsQuery,
  IAutoPromotionSlotsResponse,
  IGetPromoCodeValidationQueryTypes,
  IGetPromoCodeValidationResponse,
  IPaymentOrderInfo,
  IPaymentOrderInfoHash,
  IPayServicesRequest,
  IPayServicesResponse,
  IPostAutoPromotionArtRequest,
  IPostCirculationCreateAndSendRequest,
  IServiceGroup,
  IServiceGroupType,
} from 'sp-redux/slices/services/types';
import type { IUploadBookRequestType } from 'sp-redux/slices/uploadBook/types';
import type {
  IPublisherLoginRequestType,
  LoginQueryParamsType,
  LoginRequestType,
  RoyaltyInfoTypes,
  UserInfoType,
  UserRolesQueryType,
  VerificationCodeQueryParamsType,
} from 'sp-redux/slices/userInfo/types';
import type {
  IRegistrationQueryParamsType,
  IRegistrationRequestType,
  IRegistrationResponseType,
} from 'sp-redux/slices/userRegistration/types';
import type {
  IProfileVerificationCommentsRequest,
  IProfileVerificationType,
} from 'sp-redux/slices/verificationProfile/types';
import type { IGetAudioBookAgreementFilesRequest } from 'sp-redux/thunks/audioBooks/getAudioBookAgreementFiles';
import type { IGetAudioBookAudioFilesRequest } from 'sp-redux/thunks/audioBooks/getAudioBookAudioFiles';
import type { IGetAudioBookEmployeesByAudioIdRequest } from 'sp-redux/thunks/audioBooks/getAudioBookEmployeesByAudioId';
import type { ILegacyGetMyAudioBooksThunk } from 'sp-redux/thunks/myAudioBooks/getMyAudioBooks';
import type { IMyAudioBooksThunkProps } from 'sp-redux/thunks/myBooks/getMyAudioBooksThunk';
import type { IMyDigitalBooksThunkProps } from 'sp-redux/thunks/myBooks/getMyDigitalBooksThunk';
import type { IMyDraftBooksThunkProps } from 'sp-redux/thunks/myBooks/getMyDraftBooksThunk';
import type { IMyPodBooksThunkProps } from 'sp-redux/thunks/myBooks/getMyPoDBooksThunk';
import type { IMyBooksPodcastsThunkProps } from 'sp-redux/thunks/myBooks/getMyPodcastsThunk';
import { ITypeNotificationsThunkProps } from 'sp-redux/thunks/notifications/getTypeNotificationsThunk';
import type { INotificationReadThunkProps } from 'sp-redux/thunks/notifications/patchNotificationReadThunk';
import type { IGetPodcastEmployeesByPodcastIdRequest } from 'sp-redux/thunks/podcasts/getPodcastEmployeesByPodcastId';
import { IPublisherGeneralStatisticsThunkProps } from 'sp-redux/thunks/publisher/statistics/getPublisherGeneralStatisticsThunk';
import { IPublisherReadingStatisticsThunkProps } from 'sp-redux/thunks/publisher/statistics/getPublisherReadingStatisticsThunk';
import { IPublisherSalesStatisticsThunkProps } from 'sp-redux/thunks/publisher/statistics/getPublisherSalesStatisticsThunk';
import { IPublisherTotalStatisticsThunkProps } from 'sp-redux/thunks/publisher/statistics/getPublisherTotalStatisticsThunk';
import type { TLitresReferalStatusType } from 'sp-redux/thunks/userInfo/getReferralStatusByUserIdThunk';

import type { IPaginationQueryObject } from 'utils/usePagination';

import { ICreateNewPodcastReturnType } from '../entities/Podcast/queries/createNewPodcast';
import {
  ICheckPasswordTokenQueryParams,
  IConfirmNewPasswordRequest,
} from '../modules/account/types';
import {
  ICreateNewAudioBookRequest,
  ICreateNewAudioBookResponse,
} from '../modules/audio-publication-master/types';
import { IReaderResponse } from '../modules/fake-reader/types';
import { ICreateArtPromotionServiceRequest } from '../modules/promo/helpers/createArtPromotionService';
import {
  IPublisherArtsSelectAutocompleteQueryParamsType,
  IPublisherFilterArtResponse,
} from '../modules/publisher/statistics/types';

type TPathParamsType = Record<string, string | number>;

interface IApiPoint {
  path: string | null;
  getPath: ((pathParams: TPathParamsType) => string) | null;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
}

type TApiPoints = Record<string, IApiPoint>;

export const apiPoints: TApiPoints = {
  userInfo: {
    path: 'api/account/info/my/',
    getPath: null,
    method: 'GET',
  },
  login: {
    path: 'api/account/login/',
    getPath: null,
    method: 'POST',
  },
  loginReferral: {
    path: 'api/litres/referrals/',
    getPath: null,
    method: 'POST',
  },
  logout: {
    path: 'api/account/logout/',
    getPath: null,
    method: 'GET',
  },
  registration: {
    path: 'api/account/registration/',
    getPath: null,
    method: 'POST',
  },
  restorePassword: {
    path: 'api/account/password_reset/',
    getPath: null,
    method: 'GET',
  },
  getRoyaltyInfo: {
    path: 'api/statistics/royalty/info/',
    getPath: null,
    method: 'GET',
  },
  getRoyaltyBanner: {
    path: 'api/statistics/royalty/banner/',
    getPath: null,
    method: 'GET',
  },
  getRoyaltyReport: {
    path: 'api/statistics/royalty/reports/',
    getPath: null,
    method: 'GET',
  },
  getRoyaltyDraft: {
    path: 'api/statistics/royalty/draft/',
    getPath: null,
    method: 'GET',
  },
  getRoyaltyPayout: {
    path: 'api/statistics/royalty/payout/',
    getPath: null,
    method: 'GET',
  },
  myBooksCount: {
    path: 'api/books/my_books/counter/',
    getPath: null,
    method: 'GET',
  },
  myBooks: {
    path: 'api/books/my_books/',
    getPath: null,
    method: 'GET',
  },
  myBooksDigitalBooks: {
    path: 'api/books/my_books/ebook/',
    getPath: null,
    method: 'GET',
  },
  myBooksDraftBooks: {
    path: 'api/books/my_books/draft/',
    getPath: null,
    method: 'GET',
  },
  myBooksAudioBooks: {
    path: 'api/books/my_books/audiobook/',
    getPath: null,
    method: 'GET',
  },
  myBooksPodBooks: {
    path: 'api/books/my_books/printed/',
    getPath: null,
    method: 'GET',
  },
  myBooksPodcasts: {
    path: 'api/books/my_books/podcasts/',
    getPath: null,
    method: 'GET',
  },
  myAudioBooks: {
    path: 'api/audio/',
    getPath: null,
    method: 'GET',
  },
  getAudioBookMetaByAudioBookId: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/audio/master/${params.bookId}/`,
    method: 'GET',
  },
  getAudioBookMetaByBookId: {
    path: '',
    getPath: (params: TPathParamsType): string => `api/audio/${params.bookId}`,
    method: 'GET',
  },
  getAudioBookMetaInAudioPublicationMaster: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/audio/meta/${params.bookId}`,
    method: 'GET',
  },
  updateAudioBookMetaInAudioPublicationMaster: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/audio/meta/${params.bookId}`,
    method: 'PATCH',
  },
  applyAudio: {
    path: 'api/audio/configuration/',
    getPath: null,
    method: 'POST',
  },
  updateAudio: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/audio/master/${params.audioBookId}/`,
    method: 'PATCH',
  },
  removeBook: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/books/my_books/${params.bookId}/`,
    method: 'DELETE',
  },
  getBookMeta: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/books/master/meta/${params.bookId}/`,
    method: 'GET',
  },
  getDraftBookChapters: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/books/${params.bookId}/chapters/`,
    method: 'GET',
  },
  saveFreeDraftChapters: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/books/${params.bookId}/chapters/${params.chapterId}/`,
    method: 'PATCH',
  },
  saveBookMeta: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/books/master/meta/${params.bookId}/`,
    method: 'PATCH',
  },
  getGenresList: {
    path: 'api/catalog/genres/',
    getPath: null,
    method: 'GET',
  },
  getBookCover: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/books/cover/${params.bookId}/`,
    method: 'GET',
  },
  setBookCover: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/books/cover/${params.bookId}/`,
    method: 'PATCH',
  },
  uploadBook: {
    path: 'api/books/my_books/',
    getPath: null,
    method: 'POST',
  },
  createBook: {
    path: 'api/books/my_books/',
    getPath: null,
    method: 'POST',
  },
  genresAutocomplete: {
    path: 'api/books/genres/',
    getPath: null,
    method: 'GET',
  },
  profilesAutocomplete: {
    path: 'api/moderation/autocomplete/authors/',
    getPath: null,
    method: 'GET',
  },
  moderatorsAutocomplete: {
    path: 'api/moderation/autocomplete/moderators/',
    getPath: null,
    method: 'GET',
  },
  getBookModerationHistory: {
    path: null,
    getPath: (params: TPathParamsType): string =>
      `api/moderation/history/${params.bookId}/book/`,
    method: 'GET',
  },
  getProfileModerationHistory: {
    path: null,
    getPath: (params: TPathParamsType): string =>
      `api/moderation/history/${params.profileId}/profile/`,
    method: 'GET',
  },
  getCoverData: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/books/cover/${params.bookId}/generation/`,
    method: 'GET',
  },
  getAchievementProgressData: {
    path: 'api/achievements/progress/my/',
    getPath: null,
    method: 'GET',
  },
  getAchievementProgressDataForMetric: {
    path: null,
    getPath: (params: TPathParamsType): string =>
      `api/achievements/progress/${getSlugByMetricType(
        params.metricType as EMetricTypes,
      )}/my/`,
    method: 'GET',
  },
  getAchievementRewardsData: {
    path: 'api/achievements/rewards/my/',
    getPath: null,
    method: 'GET',
  },
  getAchievementsGifts: {
    path: 'api/achievements/gifts/my/',
    getPath: null,
    method: 'GET',
  },
  getAchievementsGiftPromo: {
    path: null,
    getPath: (params: TPathParamsType): string =>
      `api/achievements/gifts/${String(params.giftType).toLowerCase()}/my/`,
    method: 'GET',
  },
  updateAchievementGiftSeen: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/achievements/gifts/${String(params.giftType).toLowerCase()}/my/`,
    method: 'PATCH',
  },
  updateAchievementProgressSeen: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/achievements/progress/${params.metricType}/my/`,
    method: 'PATCH',
  },
  updateAchievementRewardSeen: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/achievements/rewards/${params.rewardType}/my/`,
    method: 'PATCH',
  },
  tagsAutocomplete: {
    path: 'api/books/tags/',
    getPath: null,
    method: 'GET',
  },
  tagsByGenres: {
    path: 'api/tags/',
    getPath: null,
    method: 'GET',
  },
  mainPageBanners: {
    path: 'api/pages/banner/',
    getPath: null,
    method: 'GET',
  },
  mainPageReviews: {
    path: 'api/pages/reviews/',
    getPath: null,
    method: 'GET',
  },
  mainPagesDigitsNew: {
    path: 'api/pages/digits/',
    getPath: null,
    method: 'GET',
  },
  mainPageFaq: {
    path: 'api/pages/faq/',
    getPath: null,
    method: 'GET',
  },
  pagesStatic: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/pages/pages/${params.slug}/`,
    method: 'GET',
  },
  getProfile: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/account/profile/${params.profileId}/`,
    method: 'GET',
  },
  getDocument: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/account/document/${params.profileId}/`,
    method: 'GET',
  },
  saveProfile: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/account/profile/${params.profileId}/`,
    method: 'PATCH',
  },
  sendProfileToModeration: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/account/profile/${params.profileId}/submit/`,
    method: 'POST',
  },
  saveDocument: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/account/document/${params.profileId}/`,
    method: 'PATCH',
  },
  getUserDirectAgreement: {
    path: 'api/account/direct-agreement/',
    getPath: null,
    method: 'GET',
  },
  getFeedbackTopics: {
    path: 'api/feedback/types/',
    getPath: null,
    method: 'GET',
  },
  sendFeedback: {
    path: 'api/feedback/',
    getPath: null,
    method: 'POST',
  },
  signUpReferralProgram: {
    path: 'api/litres/referrals/',
    getPath: null,
    method: 'POST',
  },
  getAccountData: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/account/info/${params.userId}/`,
    method: 'GET',
  },
  getRoyaltyDataBySession: {
    path: 'api/litres/referrals/royalty/',
    getPath: null,
    method: 'GET',
  },
  reqAuthInReferralCabinetOfLitres: {
    path: 'api/litres/referrals/ots/',
    getPath: null,
    method: 'GET',
  },
  getOfferBaseInfo: {
    path: 'api/account/offer/info/',
    getPath: null,
    method: 'GET',
  },
  getEmployeesRolesForBook: {
    path: 'api/authors/',
    getPath: null,
    method: 'GET',
  },
  getEmployeesRoleTypes: {
    path: 'api/authors/types/',
    getPath: null,
    method: 'GET',
  },
  getEmployees: {
    path: 'api/authors/employees/',
    getPath: null,
    method: 'GET',
  },
  sendEmployee: {
    path: 'api/authors/employees/',
    getPath: null,
    method: 'POST',
  },
  sendCommentReply: {
    path: 'api/books/replies/',
    getPath: null,
    method: 'POST',
  },
  requestAdvancedStatus: {
    path: 'api/account/offer/upgrade/',
    getPath: null,
    method: 'POST',
  },
  advancedOfferAgreement: {
    path: 'api/account/offer-agreement/advanced/',
    getPath: null,
    method: 'POST',
  },
  acceptAnOffer: {
    path: 'api/account/offer-agreement/',
    getPath: null,
    method: 'POST',
  },
  passwordRoyaltyValidation: {
    path: 'api/account/token_auth/',
    getPath: null,
    method: 'POST',
  },
  sendRoyaltyOtc: {
    path: 'api/account/send_otc/',
    getPath: null,
    method: 'POST',
  },
  checkRoyaltyOtc: {
    path: 'api/account/check_otc_code/',
    getPath: null,
    method: 'POST',
  },
  allQueueBooksReturns: {
    path: 'api/moderation/queue/returns/',
    getPath: null,
    method: 'GET',
  },
  allQueueBooksNew: {
    path: 'api/moderation/queue/new/',
    getPath: null,
    method: 'GET',
  },
  allQueueProfileNew: {
    path: 'api/moderation/profile/queue/new/',
    getPath: null,
    method: 'GET',
  },
  allQueueProfileReturns: {
    path: 'api/moderation/profile/queue/returns/',
    getPath: null,
    method: 'GET',
  },
  getVerificationProfile: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/moderation/profile/${params.id}/`,
    method: 'GET',
  },
  updateVerificationProfile: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/moderation/profile/${params.id}/`,
    method: 'PUT',
  },
  myBooksQueueNew: {
    path: 'api/moderation/books/my/new/',
    getPath: null,
    method: 'GET',
  },
  myBooksQueueReturns: {
    path: 'api/moderation/books/my/returns/',
    getPath: null,
    method: 'GET',
  },
  myQueueProfilesNew: {
    path: 'api/moderation/profile/queue/my/new/',
    getPath: null,
    method: 'GET',
  },
  myQueueProfilesReturns: {
    path: 'api/moderation/profile/queue/my/returns/',
    getPath: null,
    method: 'GET',
  },
  finalQueueBooksReturns: {
    path: 'api/moderation/books/final/returns/',
    getPath: null,
    method: 'GET',
  },
  finalQueueBooksNew: {
    path: 'api/moderation/books/final/new/',
    getPath: null,
    method: 'GET',
  },
  finalQueueBooksErrors: {
    path: 'api/moderation/books/final/errors/',
    getPath: null,
    method: 'GET',
  },
  finalQueueBooksFreezed: {
    path: 'api/moderation/books/final/freezed/',
    getPath: null,
    method: 'GET',
  },
  getFinalQueueModeration: {
    path: 'api/moderation/books/final/',
    getPath: null,
    method: 'GET',
  },
  getCountModerationQueue: {
    path: 'api/moderation/count/',
    getPath: null,
    method: 'GET',
  },
  getOriginalBookInfo: {
    path: null,
    getPath: (params: TPathParamsType): string => `api/books/${params.bookId}/`,
    method: 'GET',
  },
  takeBooksToModeration: {
    path: 'api/moderation/books/',
    getPath: null,
    method: 'POST',
  },
  getModerationInfo: {
    path: null,
    getPath: (params: TPathParamsType): string =>
      `api/moderation/books/${params.moderationId}/`,
    method: 'GET',
  },
  takeProfileToModeration: {
    path: 'api/moderation/profile/',
    getPath: null,
    method: 'POST',
  },
  passportInfo: {
    path: 'api/account/document/parse_code_division/',
    getPath: null,
    method: 'POST',
  },
  checkUpdateInn: {
    path: 'api/account/tax-data/check_update_inn/',
    getPath: null,
    method: 'POST',
  },
  updateYookassaStatus: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/account/tax-data/${params.userId}/`,
    method: 'PATCH',
  },
  sendRoyaltyForm: {
    path: 'api/statistics/royalty/payout/',
    getPath: null,
    method: 'POST',
  },
  sendNaturalPersonFormAccounting: {
    path: 'api/account/payment-request-data/',
    getPath: null,
    method: 'POST',
  },
  resendMail: {
    path: 'api/account/activation-email/',
    getPath: null,
    method: 'POST',
  },
  getTopSalesBooks: {
    path: null,
    getPath: (params: TPathParamsType): string =>
      `api/books/top-sales/?genre=${params.genre}`,
    method: 'GET',
  },
  getConvertingBookFile: {
    path: null,
    getPath: (params: TPathParamsType): string =>
      `api/books/file/${params.bookId}/`,
    method: 'GET',
  },
  getCharactersCount: {
    path: null,
    getPath: (params: TPathParamsType): string =>
      `api/books/${params.bookId}/stats/`,
    method: 'GET',
  },
  getBookPrices: {
    path: 'api/litres/prices/',
    getPath: null,
    method: 'GET',
  },
  getBookCommentsForModerator: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/moderation/book-comments/?book_id=${params.bookId}`,
    method: 'GET',
  },
  getBookCommentsForUser: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/books/moderation-comment/${params.bookId}`,
    method: 'GET',
  },
  publishBook: {
    path: null,
    getPath: (params: TPathParamsType): string =>
      `api/books/master/meta/${params.bookId}/publication/`,
    method: 'POST',
  },
  getOwners: {
    path: 'api/litres/owners/',
    getPath: null,
    method: 'GET',
  },
  getAudioOwners: { path: 'api/audio/owners/', getPath: null, method: 'GET' },
  getCommentTemplates: {
    path: 'api/moderation/comments',
    getPath: null,
    method: 'GET',
  },
  sendBookToReject: {
    path: null,
    getPath: (params: TPathParamsType): string =>
      `api/moderation/books/${params.moderationId}/`,
    method: 'PUT',
  },
  setBookModerationState: {
    path: 'api/moderation/books/',
    getPath: (params: TPathParamsType): string => {
      return `api/moderation/books/${params.moderationId}/`;
    },
    method: 'PUT',
  },
  unpublishBook: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/books/my_books/${params.bookId}/unpublish/`,
    method: 'POST',
  },
  cancelUnpublishingRequest: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/books/my_books/${params.bookId}/unpublish/`,
    method: 'DELETE',
  },
  cancelAudioBookUnpublishingRequest: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/audio/master/${params.audioId}/unpublish`,
    method: 'DELETE',
  },
  getBookDiscountsInfo: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/discount/art-discounts/${params.bookId}/`,
    method: 'GET',
  },
  getBookDiscountsList: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/discount/art-discounts/${params.bookId}/discounts/`,
    method: 'GET',
  },
  setBookDiscount: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/discount/art-discounts/${params.bookId}/discounts/`,
    method: 'POST',
  },
  getFB2asJSON: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/books/master/formatted_fb2/${params.bookId}/`,
    method: 'GET',
  },
  getChapterFB2asJSON: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/books/master/formatted_fb2/${params.bookId}/chapters/${params.chapterId}/`,
    method: 'GET',
  },
  getAudioBookAudioFiles: {
    path: 'api/audio/file/audio/',
    getPath: (params: TPathParamsType): string =>
      `api/audio/file/audio/?audio_book_id=${params.bookId}`,
    method: 'GET',
  },
  getAudioBookAgreementFiles: {
    path: 'api/audio/file/agreement/',
    getPath: (params: TPathParamsType): string =>
      `api/audio/file/agreement/?audio_book_id=${params.bookId}`,
    method: 'GET',
  },
  setOrderAudioBookFiles: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/audio/file/audio/${params.audioId}/`,
    method: 'PATCH',
  },
  transferRightsAudiobook: {
    path: 'api/audio/configuration/rights/',
    getPath: null,
    method: 'POST',
  },
  getAudioBookEmployees: {
    path: 'api/audio/master/employees/',
    getPath: (params: TPathParamsType): string =>
      `api/audio/master/${params.bookId}/employees/`,
    method: 'GET',
  },
  getStatisticsChart: {
    path: 'api/statistics/get-chart/',
    getPath: null,
    method: 'POST',
  },
  getRawSums: {
    path: 'api/statistics/get-raw-sums/',
    getPath: null,
    method: 'POST',
  },
  getReadingStatistics: {
    path: 'api/statistics/get-raw/',
    getPath: null,
    method: 'POST',
  },
  getRoyaltyOverall: {
    path: 'api/statistics/royalty/',
    getPath: null,
    method: 'GET',
  },
  notificationsCount: {
    path: 'api/notifications/count/',
    getPath: null,
    method: 'GET',
  },
  getNotificationsByType: {
    path: 'api/notifications/',
    getPath: (params: TPathParamsType): string =>
      `api/notifications/?type=${params.type}/`,
    method: 'GET',
  },
  getAllNotifications: {
    path: 'api/notifications/',
    getPath: null,
    method: 'GET',
  },
  notificationRead: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/notifications/${params.id}/`,
    method: 'PATCH',
  },
  getAudioRoyaltyStatistics: {
    path: 'api/statistics/table/audio_books/',
    getPath: null,
    method: 'GET',
  },
  getBookRoyaltyStatistics: {
    path: 'api/statistics/table/ebooks/',
    getPath: null,
    method: 'GET',
  },
  getStatisticsAccessibility: {
    path: 'api/statistics/forbidden/',
    getPath: null,
    method: 'GET',
  },
  getBookRoyaltySums: {
    path: 'api/statistics/table/ebooks-sums/',
    getPath: null,
    method: 'GET',
  },
  getAudioRoyaltySums: {
    path: 'api/statistics/table/audiobooks-sums/',
    getPath: null,
    method: 'GET',
  },
  getAudioBookCover: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/audio/master/cover/${params.audioId}/`,
    method: 'GET',
  },
  setAudioBookCover: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/audio/master/cover/${params.audioId}/`,
    method: 'PATCH',
  },
  getAudioBookCoverProofDocuments: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/audio/master/file/cover_proof_documents/${params.audioId}/`,
    method: 'GET',
  },
  addAudioBookCoverProofDocuments: {
    path: 'api/audio/master/file/cover_proof_documents/',
    getPath: null,
    method: 'POST',
  },
  getAudioBookModerationComments: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/audio/configuration/${params.audioId}/comment/`,
    method: 'GET',
  },
  createSeries: {
    path: 'api/series/',
    getPath: null,
    method: 'POST',
  },
  patchSeriesDeatils: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/series/${params.seriesId}/`,
    method: 'PATCH',
  },
  getSeries: {
    path: 'api/series',
    getPath: null,
    method: 'GET',
  },
  deleteSeries: {
    path: 'api/series',
    getPath: (params: TPathParamsType): string =>
      `api/series/${params.seriesId}/`,
    method: 'DELETE',
  },
  getSeriesDetails: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/series/${params.seriesId}/`,
    method: 'GET',
  },
  editSeries: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/series/${params.seriesId}/`,
    method: 'PATCH',
  },
  publishSeries: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/series/${params.seriesId}/publication/`,
    method: 'POST',
  },
  seriesCount: {
    path: 'api/series/counters/',
    getPath: null,
    method: 'GET',
  },
  getUserArtsForSeries: {
    path: 'api/series/book-search/',
    getPath: null,
    method: 'GET',
  },
  getOauthLitresScope: {
    path: 'api/litres/oauth/scope/',
    getPath: null,
    method: 'POST',
  },
  getOauthLitresFinalize: {
    path: 'api/litres/oauth/finalize/',
    getPath: null,
    method: 'POST',
  },
  getBookParseId: {
    path: 'api/books/import/parse/',
    getPath: null,
    method: 'POST',
  },
  getParsedBooks: {
    path: 'api/books/import/',
    getPath: (params: TPathParamsType): string =>
      `api/books/import/${params.parseId}/`,
    method: 'GET',
  },
  postImportIds: {
    path: 'api/books/import/',
    getPath: null,
    method: 'POST',
  },
  getServicesList: {
    path: 'api/services/service_types/',
    getPath: null,
    method: 'GET',
  },
  getCirculationCost: {
    path: 'api/services/circulation/cost/',
    getPath: null,
    method: 'GET',
  },
  getServiceTypesGroup: {
    path: 'api/services/service_types_groups/',
    getPath: null,
    method: 'GET',
  },
  getServicePromocode: {
    path: 'api/services/promocode/',
    getPath: null,
    method: 'GET',
  },
  payServices: {
    path: 'api/services/promo/',
    getPath: null,
    method: 'POST',
  },
  getOrderInfoByOrderId: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/services/promo/${params.orderId}/`,
    method: 'GET',
  },
  getPodcastMetaByPodcastId: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/podcasts/${params.podcastId}/`,
    method: 'GET',
  },
  getPodcastEmployees: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/podcasts/${params.podcastId}/employees`,
    method: 'GET',
  },
  getPodcastModerationComments: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/podcasts/${params.podcastId}/comment/`,
    method: 'GET',
  },
  getCirculationOrders: {
    path: 'api/services/circulation/',
    getPath: null,
    method: 'GET',
  },
  getCirculationOrder: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/services/circulation/${params.orderId}/`,
    method: 'GET',
  },
  getAccountTaxDataByUserId: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/account/tax-data/${params.userId}/`,
    method: 'GET',
  },
  postCirculationCreateAndSend: {
    path: 'api/services/circulation/create_and_send/',
    getPath: null,
    method: 'POST',
  },
  patchCirculationOrder: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/services/circulation/${params.orderId}/`,
    method: 'PATCH',
  },
  sendCirculationOrder: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/services/circulation/${params.orderId}/send/`,
    method: 'POST',
  },
  cancelCirculationOrder: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/services/circulation/${params.orderId}/cancel/`,
    method: 'POST',
  },
  deleteCirculationOrder: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/services/circulation/${params.orderId}/`,
    method: 'DELETE',
  },
  getEncryptKey: {
    path: 'api/statistics/mandarin/public-encryption-key/',
    getPath: null,
    method: 'GET',
  },
  getMandarinRegisterUser: {
    path: 'api/statistics/mandarin/register/',
    getPath: null,
    method: 'POST',
  },
  getPromotionsOrders: {
    path: 'api/services/promo/',
    getPath: null,
    method: 'GET',
  },
  getCirculationCoverGeneration: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/books/cover/${params.bookId}/generation`,
    method: 'GET',
  },
  postCirculationCoverGeneration: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/books/cover/${params.bookId}/generation`,
    method: 'POST',
  },
  getCirculationPdfGeneration: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/books/pdf/${params.bookId}/generation`,
    method: 'GET',
  },
  getPaymentOrderInfo: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/payonline/payment/order-info/${params.hash}/`,
    method: 'GET',
  },
  getBookEditorData: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/editor/${params.bookId}/`,
    method: 'GET',
  },
  updateBookEditorData: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/editor/${params.bookId}/`,
    method: 'PATCH',
  },
  getPublisherGeneralStatistics: {
    path: 'api/publishers/report/dashboard/',
    getPath: null,
    method: 'GET',
  },
  getPublisherTotalStatistics: {
    path: 'api/publishers/report/dashboard-total-sum/',
    getPath: null,
    method: 'GET',
  },
  getPublisherSalesStatistics: {
    path: 'api/publishers/report/royalty-report-by-rightholder/',
    getPath: null,
    method: 'GET',
  },
  getPublisherReadingStatistics: {
    path: 'api/publishers/report/child-free-royalty-report-by-art/',
    getPath: null,
    method: 'GET',
  },
  getPublisherRoyaltyReportByPerson: {
    path: 'api/publishers/report/royalty-report-by-person/',
    getPath: null,
    method: 'GET',
  },
  getPersonRoyaltyReportByArt: {
    path: 'api/publishers/report/person-royalty-report-by-art/',
    getPath: null,
    method: 'GET',
  },
  getRightholderArts: {
    path: 'api/publishers/report/person-arts/',
    getPath: null,
    method: 'GET',
  },
  getModerationSnapshots: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/books/snapshots/${params.bookId}/`,
    method: 'GET',
  },
  publisherLogin: {
    path: 'api/account/publisher-login/',
    getPath: null,
    method: 'POST',
  },
  startAIGenerateCover: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/books/cover/${params.bookId}/ai-generation/`,
    method: 'POST',
  },
  getAIGenerateCoverStatus: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/books/cover/${params.bookId}/ai-generation/`,
    method: 'GET',
  },
  getServicesOrders: {
    path: 'api/services/orders/',
    getPath: null,
    method: 'GET',
  },
  getServiceInfoByOrderId: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/services/orders/${params.orderId}/`,
    method: 'GET',
  },
  getPersonsList: {
    path: 'api/persons/',
    getPath: null,
    method: 'GET',
  },
  getPerson: {
    path: null,
    getPath: (params: TPathParamsType): string =>
      `api/persons/${params.personId}`,
    method: 'GET',
  },
  patchPerson: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/persons/${params.personId}/`,
    method: 'PATCH',
  },
  getAutoPromotionSlots: {
    path: 'api/services/promo/auto-slots/',
    getPath: null,
    method: 'GET',
  },
  postAutoPromotionArt: {
    path: 'api/services/promo/auto/',
    getPath: null,
    method: 'POST',
  },
  generateAIAnnotation: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/books/master/meta/${params.bookId}/generate-ai-annotation/`,
    method: 'POST',
  },
  getAutoPromotionStatistics: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/services/promo/${params.orderId}/statistics/`,
    method: 'GET',
  },
  getReaderContent: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/books/reader/${params.bookId}/`,
    method: 'GET',
  },
  createNewAudioBook: {
    path: 'api/audio/master/',
    getPath: null,
    method: 'POST',
  },
  checkPasswordToken: {
    path: 'api/account/set_password/',
    getPath: null,
    method: 'GET',
  },
  confirmNewPassword: {
    path: 'api/account/set_password/',
    getPath: null,
    method: 'POST',
  },
  deleteChapter: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/books/${params.bookId}/chapters/${params.chapterId}/`,
    method: 'DELETE',
  },
  updateConfiguratorAudioBookMetaByAudio: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/audio/configuration/${params.audioBookId}/`,
    method: 'PATCH',
  },
  unpublishAudioBookById: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/audio/master/${params.audioId}/unpublish/`,
    method: 'POST',
  },
  publishPodcast: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/podcasts/${params.podcastId}/publication/`,
    method: 'POST',
  },
  deleteAudioBookById: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/audio/master/${params.audioId}/`,
    method: 'DELETE',
  },
  createNewPodcast: {
    path: 'api/podcasts/',
    getPath: null,
    method: 'POST',
  },
  updatePodcastEmployeesList: {
    path: '',
    getPath: (params: TPathParamsType): string =>
      `api/podcasts/${params.id}/employees/`,
    method: 'PATCH',
  },
  updatePodcastMetaByPodcastId: {
    path: '',
    getPath: (params: TPathParamsType): string => `api/podcasts/${params.id}/`,
    method: 'PATCH',
  },
  createArtPromotionService: {
    path: 'api/services/service/promotion_package/',
    getPath: null,
    method: 'POST',
  },
  getAvailibleDiscountsArts: {
    path: 'api/discount/discountable-arts/',
    getPath: null,
    method: 'GET',
  },
  getDiscountsArts: {
    path: 'api/discount/discounts/',
    getPath: null,
    method: 'GET',
  },
};

export type TAvailableApiPoints = keyof typeof apiPoints;

export interface IApiPointsFetchParamsType {
  userInfo: {
    request: null;
    queryParams: LoginQueryParamsType;
    response: UserInfoType | null;
  };
  getAccountData: {
    request: null;
    queryParams: null;
    response: {
      litres_referral: TLitresReferalStatusType;
    };
  };
  getRoyaltyDataBySession: {
    request: null;
    queryParams: null;
    response: { total_sum: number; amount: number };
  };
  reqAuthInReferralCabinetOfLitres: {
    request: null;
    queryParams: null;
    response: { url?: string };
  };
  login: {
    request: LoginRequestType;
    queryParams: null;
    response: UserInfoType;
  };
  loginReferral: {
    request: LoginRequestType;
    queryParams: null;
    response: Record<string, unknown>;
  };
  logout: {
    request: null;
    queryParams: null;
    response: null;
  };
  registration: {
    request: IRegistrationRequestType;
    queryParams: IRegistrationQueryParamsType;
    response: IRegistrationResponseType;
  };
  restorePassword: {
    request: null;
    queryParams: IRestorePasswordParamsType;
    response: null;
  };
  getRoyaltyInfo: {
    request: null;
    queryParams: null;
    response: RoyaltyInfoTypes;
  };
  getRoyaltyBanner: {
    request: null;
    queryParams: null;
    response: IRoyaltyBannerResponse;
  };
  getRoyaltyReport: {
    request: null;
    queryParams: null;
    response: IRoyaltyReportResponse[];
  };
  getRoyaltyDraft: {
    request: null;
    queryParams: null;
    response: IRoyaltyDraftResponse[];
  };
  getRoyaltyPayout: {
    request: null;
    queryParams: null;
    response: IRoyaltyPayoutResponse[];
  };
  myBooks: {
    request: null;
    queryParams: IMyBooksQueryParams;
    response: IMyBook[];
  };
  myBooksDigitalBooks: {
    request: null;
    queryParams: IMyDigitalBooksThunkProps;
    response: IMyBook[];
  };
  myBooksDraftBooks: {
    request: null;
    queryParams: IMyDraftBooksThunkProps;
    response: IMyBook[];
  };
  myBooksAudioBooks: {
    request: null;
    queryParams: IMyAudioBooksThunkProps;
    response: IMyBook[];
  };
  myBooksPodBooks: {
    request: null;
    queryParams: IMyPodBooksThunkProps;
    response: IMyBook[];
  };
  myBooksPodcasts: {
    request: null;
    queryParams: IMyBooksPodcastsThunkProps;
    response: IMyBook[];
  };
  myBooksCount: {
    request: null;
    queryParams: null;
    response: IMyBooksCount;
  };
  myAudioBooks: {
    request: null;
    queryParams: ILegacyGetMyAudioBooksThunk;
    response: TMyAudioBooksType;
  };
  getAudioBookMetaByAudioBookId: {
    request: null;
    queryParams: null;
    response: IAudioBook;
  };
  getAudioBookMetaByBookId: {
    request: null;
    queryParams: null;
    response: IAudioBook;
  };
  applyAudio: {
    request: IApplyAudioRequestType;
    queryParams: null;
    response: IApplyAudioResponseType;
  };
  removeBook: {
    request: null;
    queryParams: null;
    response: IBook[];
  };
  getBookMeta: {
    request: null;
    queryParams: null;
    response: IServerBookMetaType;
  };
  getDraftBookChapters: {
    request: null;
    queryParams: null;
    response: IChapter[];
  };
  getGenresList: {
    request: null;
    queryParams: null;
    response: IGenreGroupedOption[];
  };
  getCoverData: {
    request: null;
    queryParams: null;
    response: ICoverDataType;
  };
  getAchievementProgressData: {
    request: null;
    queryParams: null;
    response: IAchievementProgressDataType[];
  };
  getAchievementProgressDataForMetric: {
    request: null;
    queryParams: IAchievementProgressForSalesQueryParams;
    response: IAchievementProgressDataType;
  };
  getAchievementRewardsData: {
    request: null;
    queryParams: null;
    response: IAchievementRewardsDataType[];
  };
  getAchievementsGifts: {
    request: null;
    queryParams: null;
    response: IAchievementsGiftsType[];
  };
  getAchievementsGiftPromo: {
    request: null;
    queryParams: IAchievementsGiftPromoQueryParams;
    response: IAchievementsGiftPromoType;
  };
  updateAchievementGiftSeen: {
    request: IAchievementSeenRequest;
    queryParams: { giftType: EGiftTypes };
    response: null;
  };
  updateAchievementProgressSeen: {
    request: IAchievementSeenRequest;
    queryParams: IAchievementProgressSeenQueryParams;
    response: null;
  };
  updateAchievementRewardSeen: {
    request: IAchievementSeenRequest;
    queryParams: IAchievementRewardSeenQueryParams;
    response: null;
  };
  getBookModerationHistory: {
    request: null;
    queryParams: null;
    response: IModerationBookHistoryItem[];
  };
  getProfileModerationHistory: {
    request: null;
    queryParams: null;
    response: IModerationBookHistoryItem[];
  };
  getBookCover: {
    request: null;
    queryParams: null;
    response: IBookCoverInfo | null;
  };
  setBookCover: {
    request: Partial<IBookCoverInfo>;
    queryParams: null;
    response: IBookCoverInfo | null;
  };
  saveFreeDraftChapters: {
    request: Partial<IChapter>;
    queryParams: null;
    response: null;
  };
  saveBookMeta: {
    request: Partial<ISavableBookMetaType>;
    queryParams: null;
    response: IBookMetaType;
  };
  uploadBook: {
    request: IUploadBookRequestType;
    queryParams: null;
    response: IBookMetaType;
  };
  createBook: {
    request: ICreateBookRequestType;
    queryParams: null;
    response: IBookMetaType;
  };
  pagesStatic: {
    request: null;
    queryParams: null;
    response: IStaticPageType;
  };
  genresAutocomplete: {
    request: null;
    queryParams: IGenresAutocompleteRequestQueryParamsType;
    response: IGenresAutocompleteResponseType;
  };
  profilesAutocomplete: {
    request: null;
    queryParams: IProfileAutocompleteRequestQueryParamsType;
    response: IResponseProfileAutocompleteTypes;
  };
  moderatorsAutocomplete: {
    request: null;
    queryParams: IModeratorsAutocompleteRequestQueryParamsType;
    response: IResponseModeratorsAutocompleteTypes;
  };
  tagsAutocomplete: {
    request: null;
    queryParams: ITagsAutocompleteRequestQueryParamsType;
    response: ITagsAutocompleteResponseType;
  };
  tagsByGenres: {
    request: null;
    queryParams: ITagsByGenresRequestQueryParamsType;
    response: ITag[];
  };
  getProfile: {
    request: null;
    queryParams: null;
    response: IProfile;
  };
  saveProfile: {
    request: TSaveProfileRequestType;
    queryParams: null;
    response: IProfile;
  };
  sendProfileToModeration: {
    request: null;
    queryParams: null;
    response: IProfile;
  };
  getDocument: {
    request: null;
    queryParams: null;
    response: IServerDocumentType;
  };
  saveDocument: {
    request: FormData;
    queryParams: null;
    response: IProfile;
  };
  getFeedbackTopics: {
    request: null;
    queryParams: null;
    response: IFeedbackTopicsType[];
  };
  sendFeedback: {
    request: IFeedbackFormType;
    queryParams: null;
    response: IFeedbackFormType;
  };
  signUpReferralProgram: {
    request: null;
    queryParams: null;
    response: null;
  };
  sendCommentReply: {
    request: ICommentReplyRequest;
    queryParams: null;
    response: IReview | null;
  };
  getOfferBaseInfo: {
    request: null;
    queryParams: null;
    response: IOfferBaseInfoType[];
  };
  getEmployees: {
    request: null;
    queryParams: null;
    response: IEmployee[];
  };
  getEmployeesRoleTypes: {
    request: null;
    queryParams: null;
    response: IEmployeeRoleEntity[];
  };
  getEmployeesRolesForBook: {
    request: null;
    queryParams: UserRolesQueryType;
    response: IEmployee[];
  };
  sendEmployee: {
    request: IEmployeeRequest;
    queryParams: null;
    response: IEmployeeResponse;
  };
  requestAdvancedStatus: {
    request: null;
    queryParams: null;
    response: Record<string, undefined>;
  };
  getUserDirectAgreement: {
    request: null;
    queryParams: null;
    response: IUserDirectAgreementType[];
  };
  advancedOfferAgreement: {
    request: IAdvancedOfferAgreementAPIRequest;
    queryParams: null;
    response: IAdvancedOfferAgreementAPIResponse;
  };
  acceptAnOffer: {
    request: null;
    queryParams: null;
    response: null;
  };
  passwordRoyaltyValidation: {
    request: IPasswordRoyaltyValidationRequest;
    queryParams: null;
    response: IPasswordRoyaltyValidationResponse;
  };
  sendRoyaltyOtc: {
    request: Record<string, unknown>;
    queryParams: VerificationCodeQueryParamsType;
    response: ISendRoyaltyOtcResponse;
  };
  checkRoyaltyOtc: {
    request: ICheckRoyaltyOtcRequest;
    queryParams: null;
    response: ICheckRoyaltyOtcResponse;
  };
  allQueueBooksReturns: {
    request: null;
    queryParams: IFilteringQueryParamsType;
    response: IAllQueueModerationResponseTypes[];
  };
  allQueueBooksNew: {
    request: null;
    queryParams: IFilteringQueryParamsType;
    response: IAllQueueModerationResponseTypes[];
  };
  allQueueProfileNew: {
    request: null;
    queryParams: IFilteringQueryParamsType;
    response: IQueueProfileTypes[];
  };
  allQueueProfileReturns: {
    request: null;
    queryParams: IFilteringQueryParamsType;
    response: IQueueProfileTypes[];
  };
  myBooksQueueNew: {
    request: null;
    queryParams: IFilteringQueryParamsType;
    response: IMyQueueModerationResponseTypes[];
  };
  myBooksQueueReturns: {
    request: null;
    queryParams: IFilteringQueryParamsType;
    response: IMyQueueModerationResponseTypes[];
  };
  myQueueProfilesNew: {
    request: null;
    queryParams: IFilteringQueryParamsType;
    response: IQueueProfileTypes[];
  };
  getVerificationProfile: {
    request: IProfileVerificationType;
    queryParams: null;
    response: IProfileVerificationType;
  };
  updateVerificationProfile: {
    request: IProfileVerificationCommentsRequest;
    queryParams: null;
    response: IProfileVerificationCommentsRequest;
  };
  myQueueProfilesReturns: {
    request: null;
    queryParams: IFilteringQueryParamsType;
    response: IQueueProfileTypes[];
  };
  finalQueueBooksReturns: {
    request: null;
    queryParams: IFilteringQueryParamsType;
    response: IFinalQueueAPIResponseModerationTypes[];
  };
  finalQueueBooksNew: {
    request: null;
    queryParams: IFilteringQueryParamsType;
    response: IFinalQueueAPIResponseModerationTypes[];
  };
  finalQueueBooksErrors: {
    request: null;
    queryParams: IFilteringQueryParamsType;
    response: IFinalQueueAPIResponseModerationTypes[];
  };
  finalQueueBooksFreezed: {
    request: null;
    queryParams: IFilteringQueryParamsType;
    response: IFinalQueueAPIResponseModerationTypes[];
  };
  getCountModerationQueue: {
    request: null;
    queryParams: null;
    response: ICountersModerationBooksTypes;
  };
  getOriginalBookInfo: {
    request: null;
    queryParams: null;
    response: INotAuthorizedUser;
  };
  passportInfo: {
    request: IPassportInfoAPIRequest;
    queryParams: null;
    response: IPassportInfoAPIResponse;
  };
  checkUpdateInn: {
    request: ICheckUpdateINNRequest;
    queryParams: null;
    response: ICheckUpdateINNResponse;
  };
  updateYookassaStatus: {
    request: IRequestUpdateYooStatus;
    queryParams: null;
    response: IResponseUpdateYooStatus;
  };
  sendRoyaltyForm: {
    request: IFormRoyaltyApiTypes;
    queryParams: null;
    response: IFormRoyaltyApiTypes;
  };
  sendNaturalPersonFormAccounting: {
    request: ISendAccountingDataTypes;
    queryParams: null;
    response: ISendAccountingDataTypes;
  };
  takeBooksToModeration: {
    request: ITakeBooksToModerationFetchTypes;
    queryParams: null;
    response: ITakeBooksToModerationResponseTypes;
  };
  getModerationInfo: {
    request: null;
    queryParams: null;
    response: IModerationBookInfo;
  };
  takeProfileToModeration: {
    request: ITakeProfileToModerationFetchTypes;
    queryParams: null;
    response: ITakeProfileToModerationResponseTypes;
  };
  resendMail: {
    request: null;
    queryParams: null;
    response: IResendMailResponse;
  };
  getConvertingBookFile: {
    request: null;
    queryParams: null;
    response: IBookFile;
  };
  getCharactersCount: {
    request: null;
    queryParams: null;
    response: ICharactersCountType;
  };
  getBookPrices: {
    request: null;
    queryParams: null;
    response: IPrice[];
  };
  getBookCommentsForModerator: {
    request: null;
    queryParams: null;
    response: IBookCommentsResultType[];
  };
  getBookCommentsForUser: {
    request: null;
    queryParams: null;
    response: IBookCommentsResultType[];
  };
  getOwners: {
    request: null;
    queryParams: null;
    response: IOwnersItem[];
  };
  getAudioOwners: {
    request: null;
    queryParams: null;
    response: IOwnersItem[];
  };
  publishBook: {
    request: Partial<IBookMetaType>;
    queryParams: { bookId: string };
    response: Partial<IPublishBookResponse>;
  };
  getCommentTemplates: {
    request: null;
    queryParams: ICommentTemplatesRequest;
    response: ICommentTemplatesType[];
  };
  sendBookToReject: {
    request: IRejectedBook;
    queryParams: null;
    response: IModerationBookInfo;
  };
  setBookModerationState: {
    request: IUpdateBookModerationStateRequest;
    queryParams: { moderationId: string };
    response: IUpdateBookModerationStateResponse;
  };
  unpublishBook: {
    request: IUnpublishBookRequest | null;
    queryParams: null;
    response: IBook[];
  };
  cancelUnpublishingRequest: {
    request: null;
    queryParams: null;
    response: IBook[];
  };
  cancelAudioBookUnpublishingRequest: {
    request: null;
    queryParams: null;
    response: IBook[];
  };
  getBookDiscountsInfo: {
    request: null;
    queryParams: { art_type: TArtType };
    response: IDiscountsInfoResponse;
  };
  getBookDiscountsList: {
    request: null;
    queryParams: IDiscountsListQuery;
    response: IDiscountsList[];
  };
  setBookDiscount: {
    request: IBookDiscountRequest;
    queryParams: null;
    response: IBookDiscountResponse;
  };
  getFB2asJSON: {
    request: null;
    queryParams: null;
    response: Record<string, unknown>[];
  };
  getChapterFB2asJSON: {
    request: null;
    queryParams: null;
    response: Record<string, unknown>[];
  };
  getAudioBookAudioFiles: {
    request: IGetAudioBookAudioFilesRequest;
    queryParams: null;
    response: IDownloadInProgressFile[];
  };
  getAudioBookEmployees: {
    request: IGetAudioBookEmployeesByAudioIdRequest;
    queryParams: null;
    response: IEmployee[];
  };
  getAudioBookAgreementFiles: {
    request: IGetAudioBookAgreementFilesRequest;
    queryParams: null;
    response: IDownloadInProgressFile[];
  };
  setOrderAudioBookFiles: {
    request: ISetOrderAudioBookFilesRequest;
    queryParams: null;
    response: IDownloadInProgressFile;
  };
  transferRightsAudiobook: {
    request: ITransferRightsAudiobookRequestType;
    queryParams: null;
    response: ITransferRightsAudiobookResponseType;
  };
  notificationsCount: {
    request: null;
    queryParams: null;
    response: INotificationsCounters | null;
  };
  getNotificationsByType: {
    request: null;
    queryParams: ITypeNotificationsThunkProps;
    response: INotification[] | null;
  };
  getAllNotifications: {
    request: null;
    queryParams: null;
    response: INotification[] | null;
  };
  notificationRead: {
    request: null;
    queryParams: INotificationReadThunkProps;
    response: INotification;
  };
  getStatisticsChart: {
    request: IGetStatisticsChartRequestType;
    queryParams: IGetStatisticsChartQueryParamsType;
    response: IGetStatisticsChartResponseType;
  };
  getRawSums: {
    request: IGetRawSumsRequestType;
    queryParams: IGetStatisticsChartQueryParamsType;
    response: IGetRawSumsResponseType | null;
  };
  getReadingStatistics: {
    request: IGetReadingStatisticsRequestType;
    queryParams: IGetStatisticsChartQueryParamsType;
    response: IReadingStatisticsTableRow[];
  };
  getAudioRoyaltyStatistics: {
    request: null;
    queryParams: IGetRoyaltyStatisticsQueryType;
    response: IRoyaltyStatisticsTableRow[];
  };
  getBookRoyaltyStatistics: {
    request: null;
    queryParams: IGetRoyaltyStatisticsQueryType;
    response: IRoyaltyStatisticsTableRow[];
  };
  getBookRoyaltySums: {
    request: null;
    queryParams: IGetRoyaltyStatisticsSumsType;
    response: IRoyaltySums | null;
  };
  getAudioRoyaltySums: {
    request: null;
    queryParams: IGetRoyaltyStatisticsSumsType;
    response: IRoyaltySums | null;
  };
  getStatisticsAccessibility: {
    request: null;
    queryParams: null;
    response: IGetStatisticsAccessibilityResponse;
  };
  getRoyaltyOverall: {
    request: null;
    queryParams: IGetRoyaltyOverallQueryType;
    response: IGetRoyaltyOverallResponseType;
  };
  getAudioBookMetaInAudioPublicationMaster: {
    request: null;
    queryParams: null;
    response: IAudioBook;
  };
  updateAudioBookMetaInAudioPublicationMaster: {
    request: {
      [key: string]: unknown;
    };
    queryParams: null;
    response: IAudioBook;
  };
  getAudioBookCover: {
    request: null;
    queryParams: null;
    response: IAudioBookCoverInfo;
  };
  setAudioBookCover: {
    request: FormData | Partial<IAudioBookCoverInfo>;
    queryParams: null;
    response: IAudioBookCoverInfo | null;
  };
  getAudioBookCoverProofDocuments: {
    request: null;
    queryParams: null;
    response: ICoverDocumentResponseType[];
  };
  getAudioBookModerationComments: {
    request: null;
    queryParams: null;
    // пока модераторы могут прислать только один коммент
    response: IAudioBookModerationComment;
  };
  createSeries: {
    request: ICreateSeries;
    queryParams: null;
    response: ISeries;
  };
  getSeries: {
    request: null;
    queryParams: IGetSeriesQueryParams;
    response: TGetSeriesAPIResponse;
  };
  deleteSeries: {
    request: null;
    queryParams: null;
    response: null;
  };
  getSeriesDetails: {
    request: null;
    queryParams: null;
    response: TGetSeriesDetailsAPIResponse;
  };
  patchSeriesDeatils: {
    request: IPatchSeriesRequest;
    queryParams: null;
    response: ISeries;
  };
  editSeries: {
    request: IEditSeriesAPIRequest;
    queryParams: null;
    response: TEditSeriesAPIResponse;
  };
  publishSeries: {
    request: null;
    queryParams: null;
    response: TEditSeriesAPIResponse;
  };
  getUserArtsForSeries: {
    request: null;
    queryParams: ISeriesArtSearchQueryParams;
    response: ISeriesArtSearch[];
  };
  getOauthLitresScope: {
    request: null;
    queryParams: null;
    response: IOauthLitresScope;
  };
  getOauthLitresFinalize: {
    request: IOauthLitresFinalizePost;
    queryParams: null;
    response: IOauthLitresFinalizeResult;
  };
  getBookParseId: {
    request: IBookParseIdRequest;
    queryParams: null;
    response: IBookParseIdResult;
  };
  getParsedBooks: {
    request: null;
    queryParams: IPaginationQueryObject;
    response: IParsedBook[];
  };
  postImportIds: {
    request: IImportIdsRequest;
    queryParams: null;
    response: null;
  };
  seriesCount: {
    request: null;
    queryParams: null;
    response: ISeriesCounters;
  };
  getServicesList: {
    request: null;
    queryParams: null;
    response: IService[];
  };
  getServiceTypesGroup: {
    request: null;
    queryParams: IServiceGroupType;
    response: IServiceGroup[];
  };
  getServicePromocode: {
    request: null;
    queryParams: IGetPromoCodeValidationQueryTypes;
    response: IGetPromoCodeValidationResponse;
  };
  payServices: {
    request: IPayServicesRequest;
    queryParams: null;
    response: IPayServicesResponse;
  };
  getOrderInfoByOrderId: {
    request: null;
    queryParams: null;
    response: IPromotionOrder;
  };
  getPodcastMetaByPodcastId: {
    request: null;
    queryParams: null;
    response: IPodcast;
  };
  getPodcastEmployees: {
    request: IGetPodcastEmployeesByPodcastIdRequest;
    queryParams: null;
    response: { employees: IEmployee[] };
  };
  getPodcastModerationComments: {
    request: null;
    queryParams: null;
    // пока модераторы могут прислать только один коммент
    response: IPodcastModerationComment;
  };
  getCirculationOrders: {
    request: null;
    queryParams: IGetCirculationOrdersQueryParams;
    response: TGetCirculationOrdersAPIResponse;
  };
  getCirculationOrder: {
    request: null;
    queryParams: null;
    response: ICirculationOrder;
  };
  getCirculationCost: {
    request: null;
    queryParams: ICirculationCostQueryType;
    response: ICirculationCost | null;
  };
  getAccountTaxDataByUserId: {
    request: null;
    queryParams: null;
    response: IAccountTaxData;
  };
  postCirculationCreateAndSend: {
    request: IPostCirculationCreateAndSendRequest;
    queryParams: null;
    response: ICirculationOrder;
  };
  getEncryptKey: {
    request: null;
    queryParams: null;
    response: IEncryptKeyResponse;
  };
  getMandarinRegisterUser: {
    request: null;
    queryParams: null;
    response: IMandarinRegisterType;
  };
  getPromotionsOrders: {
    request: null;
    queryParams: IGetPromotionsOrdersQueryParams;
    response: IPromotionOrder[];
  };
  patchCirculationOrder: {
    request: ICirculationOrderUpdate;
    queryParams: null;
    response: ICirculationOrder;
  };
  sendCirculationOrder: {
    request: null;
    queryParams: null;
    response: ICirculationOrder;
  };
  cancelCirculationOrder: {
    request: null;
    queryParams: null;
    response: ICirculationOrder;
  };
  deleteCirculationOrder: {
    request: null;
    queryParams: null;
    response: null;
  };
  getCirculationCoverGeneration: {
    request: null;
    queryParams: ICirculationGenerationQueryType;
    response: ICirculationCover | null;
  };
  postCirculationCoverGeneration: {
    request: null;
    queryParams: ICirculationGenerationQueryType;
    response: ICirculationCover;
  };
  getCirculationPdfGeneration: {
    request: null;
    queryParams: ICirculationGenerationQueryType;
    response: ICirculationPdf | null;
  };
  getPaymentOrderInfo: {
    request: null;
    queryParams: IPaymentOrderInfoHash;
    response: IPaymentOrderInfo;
  };
  getBookEditorData: {
    request: null;
    queryParams: null;
    response: IEditorJSBlock[];
  };
  updateBookEditorData: {
    request: { blocks: IEditorJSBlock[] };
    queryParams: null;
    response: IEditorJSBlock[];
  };
  getModerationSnapshots: {
    request: null;
    queryParams: null;
    response: IBookVersions;
  };
  getPublisherGeneralStatistics: {
    request: null;
    queryParams: IPublisherGeneralStatisticsThunkProps;
    response: IPublisherStatisticsReportResponse;
  };
  getPublisherTotalStatistics: {
    request: null;
    queryParams: IPublisherTotalStatisticsThunkProps;
    response: IPublisherStatisticsReportGeneralTotal;
  };
  getPublisherSalesStatistics: {
    request: null;
    queryParams: IPublisherSalesStatisticsThunkProps;
    response: IPublisherSalesStatisticsReportResponse;
  };
  getPublisherReadingStatistics: {
    request: null;
    queryParams: IPublisherReadingStatisticsThunkProps;
    response: IPublisherReadingStatisticsReportResponse;
  };
  getPublisherRoyaltyReportByPerson: {
    request: null;
    queryParams: IPublisherSalesStatisticsThunkProps;
    response: IPublisherSalesStatisticsReportResponse | null;
  };
  getPersonRoyaltyReportByArt: {
    request: null;
    queryParams: IPublisherReadingStatisticsThunkProps;
    response: IPublisherReadingStatisticsReportResponse | null;
  };
  publisherLogin: {
    request: IPublisherLoginRequestType;
    queryParams: null;
    response: IPublisherInfoType;
  };
  getRightholderArts: {
    request: null;
    queryParams: IPublisherArtsSelectAutocompleteQueryParamsType;
    response: IPublisherFilterArtResponse[];
  };
  startAIGenerateCover: {
    request: null;
    queryParams: null;
    response: IAICoverGenerateResponse;
  };
  getAIGenerateCoverStatus: {
    request: null;
    queryParams: null;
    response: IAICoverGenerateStatusResponse;
  };
  getServicesOrders: {
    request: null;
    queryParams: IServicesOrdersQueryParams;
    response: IServicesOrders[];
  };
  getServiceInfoByOrderId: {
    request: null;
    queryParams: null;
    response: IServicesOrders;
  };
  getPersonsList: {
    request: null;
    queryParams: null;
    response: IPerson[];
  };
  getPerson: {
    request: null;
    queryParams: null;
    response: IPerson;
  };
  patchPerson: {
    request: FormData;
    queryParams: IPersonUpdateQueryType;
    response: IPerson;
  };
  getAutoPromotionSlots: {
    request: null;
    queryParams: IAutoPromotionSlotsQuery;
    response: IAutoPromotionSlotsResponse;
  };
  postAutoPromotionArt: {
    request: IPostAutoPromotionArtRequest;
    queryParams: null;
    response: IPayServicesResponse;
  };
  generateAIAnnotation: {
    request: null;
    queryParams: null;
    response: IGenerateAIAnnotationResponse;
  };
  getAutoPromotionStatistics: {
    request: null;
    queryParams: null;
    response: IAutoPromotionStatisticsResponse;
  };
  getReaderContent: {
    request: null;
    queryParams: null;
    response: IReaderResponse;
  };
  createNewAudioBook: {
    request: ICreateNewAudioBookRequest;
    queryParams: null;
    response: ICreateNewAudioBookResponse;
  };
  checkPasswordToken: {
    request: null;
    queryParams: ICheckPasswordTokenQueryParams;
    response: null;
  };
  confirmNewPassword: {
    request: IConfirmNewPasswordRequest;
    queryParams: null;
    response: null;
  };
  deleteChapter: {
    request: null;
    queryParams: null;
    response: null;
  };
  updateConfiguratorAudioBookMetaByAudio: {
    request: Partial<IAudioBookServerPayload>;
    queryParams: null;
    response: null;
  };
  unpublishAudioBookById: {
    request: IUnpublishAudioBookById;
    queryParams: null;
    response: null;
  };
  publishPodcast: {
    request: null;
    queryParams: null;
    response: null;
  };
  deleteAudioBookById: {
    request: null;
    queryParams: null;
    response: null;
  };
  createNewPodcast: {
    request: { name: string };
    queryParams: null;
    response: ICreateNewPodcastReturnType;
  };
  updateAudio: {
    request: Partial<IAudioBookServerPayload>;
    queryParams: null;
    response: null;
  };
  updatePodcastEmployeesList: {
    request: IEmployeeInRequest[];
    queryParams: null;
    response: { employees: IEmployee[] };
  };
  updatePodcastMetaByPodcastId: {
    request: Partial<IPodcastServerPayload>;
    queryParams: null;
    response: IPodcast;
  };
  createArtPromotionService: {
    request: ICreateArtPromotionServiceRequest;
    queryParams: null;
    response: null;
  };
  getAvailibleDiscountsArts: {
    request: null;
    queryParams: null;
    response: IGetAvalibleDiscountsArts;
  };
  getDiscountsArts: {
    request: null;
    queryParams: IDiscountsArtsQuery;
    response: IGetDiscountsArt[];
  };
}
