import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import type { IHydrateAction } from 'sp-redux';
import { getActiveDiscountsArtsThunk } from 'sp-redux/thunks/discounts/getActiveDiscountsArtsThunk';
import { getArchivedDiscountsArtsThunk } from 'sp-redux/thunks/discounts/getArchivedDiscountsArtsThunk';
import { getAvalibleDiscountsArtsThunk } from 'sp-redux/thunks/discounts/getAvalibleDiscountsArtsThunk';
import { getCreatedDiscountsArtsThunk } from 'sp-redux/thunks/discounts/getCreatedDiscountsArtsThunk';

import { IDiscountArtState } from './types';

const initialState: IDiscountArtState = {
  isFetching: true,
  avalibleDiscountsArts: {},
  activeDiscountsArts: null,
  createdDiscountsArts: null,
  archivedDiscountsArts: null,
  paginationActiveDiscountsArts: {},
  paginationCreatedDiscountsArts: {},
  paginationArchivedDiscountsArts: {},
  errors: [],
};

const discountsSlice = createSlice({
  name: 'discounts',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAvalibleDiscountsArtsThunk.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(getActiveDiscountsArtsThunk.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(getCreatedDiscountsArtsThunk.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(getArchivedDiscountsArtsThunk.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(
      getAvalibleDiscountsArtsThunk.fulfilled,
      (state, action) => {
        state.isFetching = false;
        state.avalibleDiscountsArts = action.payload.result;
        state.errors = action.payload.errors;
      },
    );

    builder.addCase(getActiveDiscountsArtsThunk.fulfilled, (state, action) => {
      state.isFetching = false;
      if (action.payload.pagination) {
        state.paginationActiveDiscountsArts = action.payload.pagination;
      }
      state.activeDiscountsArts = action.payload.result;
      state.errors = action.payload.errors;
    });

    builder.addCase(getCreatedDiscountsArtsThunk.fulfilled, (state, action) => {
      state.isFetching = false;
      if (action.payload.pagination) {
        state.paginationCreatedDiscountsArts = action.payload.pagination;
      }
      state.createdDiscountsArts = action.payload.result;
      state.errors = action.payload.errors;
    });

    builder.addCase(
      getArchivedDiscountsArtsThunk.fulfilled,
      (state, action) => {
        state.isFetching = false;
        if (action.payload.pagination) {
          state.paginationArchivedDiscountsArts = action.payload.pagination;
        }
        state.archivedDiscountsArts = action.payload.result;
        state.errors = action.payload.errors;
      },
    );

    builder.addCase(
      HYDRATE,
      (state, action: IHydrateAction<IDiscountArtState>) => {
        return action.payload.discounts;
      },
    );
  },
});

export const discountsReducer = discountsSlice.reducer;
